// CODE
const code = [
  {
    title: "_server",
    components: [],
    helpers: [],
    pages: ["hosting.js", "payments.js"],
    styles: [],
    type: "server",
  },
  {
    title: "30daytracker",
    components: ["Editor.js", "Header.js", "Item.js", "Settings.js"],
    helpers: ["redux.js", "variables.js"],
    pages: ["Calendar.js", "Login.js"],
    styles: [
      "App.scss",
      "Mixins.scss",
      "Variables.scss",
      [
        "_exports.scss",
        "Calendar.scss",
        "ColorPicker.scss",
        "Editor.scss",
        "Header.scss",
        "Item.scss",
        "Login.scss",
        "Settings.scss",
      ],
    ],
    type: "web",
  },
  {
    title: "calgarycakery",
    components: ["Browser.js", "Cart.js", "Header.js", "Product.js"],
    helpers: ["methods.js"],
    pages: [],
    styles: [
      "App.scss",
      "Mixins.scss",
      "Variables.scss",
      ["Browser.scss", "Cart.scss", "Header.scss", "Product.scss"],
    ],
    type: "web",
  },
  {
    title: "devinjuett",
    components: ["Header.js", "Website.js"],
    helpers: ["code-.js", "methods.js", "projects-.js", "resume-.js"],
    pages: ["Code.js", "Projects.js", "Resume.js", "Three.js"],
    styles: [
      "App.scss",
      "Mixins.scss",
      "Variables.scss",
      [
        "_exports.scss",
        "Code.scss",
        "Header.scss",
        "Projects.scss",
        "Resume.scss",
        "Website.scss",
      ],
    ],
    type: "web",
  },
  {
    title: "flappy-bird",
    components: ["Bird.js", "Floor.js", "Pipe.js", "PipeTop.js"],
    helpers: ["methods.js", "spriteSheet.js", "variables.js"],
    pages: [],
    styles: ["appStyles.js", "componentStyles.js", "webStyles.js"],
    type: "app",
  },
  {
    title: "mine-sweeper",
    components: ["Cell.js"],
    helpers: ["variables.js"],
    pages: [],
    styles: ["appStyles.js", "webStyles.js"],
    type: "app",
  },
  {
    title: "snake",
    components: ["Food.js", "Head.js", "Tail.js"],
    helpers: ["methods.js", "variables.js"],
    pages: [],
    styles: ["appStyles.js", "webStyles.js"],
    type: "app",
  },
  {
    title: "spitfire",
    components: [],
    helpers: ["methods.js", "variables.js"],
    pages: ["Game.js"],
    styles: ["App.scss", "Mixins.scss", "Variables.scss"],
    type: "web",
  },
  {
    title: "stacker3D",
    components: [],
    helpers: ["variables.js"],
    pages: ["Game.js"],
    styles: ["App.scss", "Mixins.scss", "Variables.scss"],
    type: "web",
  },
  {
    title: "traffic-run",
    components: [
      "Car.js",
      "Menu.js",
      "Track.js",
      "Trees.js",
      "Truck.js",
      "Wheel.js",
    ],
    helpers: ["methods.js", "settings.js"],
    pages: ["Game.js"],
    styles: ["App.scss", "Mixins.scss", "Variables.scss"],
    type: "web",
  },
  {
    title: "whack-a-mole",
    components: ["Menu.js", "Mole.js"],
    helpers: ["methods.js", "spriteSheet.js", "variables.js"],
    pages: [],
    styles: ["appStyles.js", "componentStyles.js", "webStyles.js"],
    type: "app",
  },
  {
    title: "wobblehops",
    components: [
      "Background.js",
      "Beer.js",
      "Paddle.js",
      "Player.js",
      "PopUp.js",
    ],
    helpers: ["methods.js", "variables.js"],
    pages: ["CreatePlayer.js", "Game.js", "Instructions.js", "Lobby.js"],
    styles: [
      "App.scss",
      "Mixins.scss",
      "Variables.scss",
      [
        "CreatePlayer.scss",
        "Game.scss",
        "Instructions.scss",
        "Lobby.scss",
        "PopUp.scss",
      ],
    ],
    type: "web",
  },
];
// EXPORT
export { code };
