// IMPORTS
import css from "../styles/modules/_exports.scss";
// RANDOM BETWEEN
const randomBetween = (min, max) => {
  return Math.random() * (max - min + 1) + min;
};
// CHECK DIMENSIONS
const checkDimensions = (withAnimation) => {
  const mobileBreakPoint = JSON.parse(css.mobileBreakPoint.slice(0, -2));
  const tabletBreakPoint = JSON.parse(css.tabletBreakPoint.slice(0, -2));
  return {
    animate: withAnimation,
    isMobile: window.innerWidth <= mobileBreakPoint,
    isTablet:
      window.innerWidth > mobileBreakPoint &&
      window.innerWidth <= tabletBreakPoint,
    isDesktop: window.innerWidth > tabletBreakPoint,
  };
};
// INITIAL SETTINGS
const initialSettings = () => {
  const userAgent = navigator.userAgent;
  const mobileBreakPoint = JSON.parse(css.mobileBreakPoint.slice(0, -2));
  const tabletBreakPoint = JSON.parse(css.tabletBreakPoint.slice(0, -2));
  return {
    browser: userAgent.match(/chrome|chromium|crios/i)
      ? "chrome"
      : userAgent.match(/firefox|fxios/i)
      ? "firefox"
      : userAgent.match(/safari/i)
      ? "safari"
      : userAgent.match(/opr\//i)
      ? "opera"
      : userAgent.match(/edg/i)
      ? "edge"
      : "",
    touchDevice:
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      ),
    animate: true,
    isMobile: window.innerWidth <= mobileBreakPoint,
    isTablet:
      window.innerWidth > mobileBreakPoint &&
      window.innerWidth <= tabletBreakPoint,
    isDesktop: window.innerWidth > tabletBreakPoint,
  };
};
// GENERATE TITLE
const generateTitle = (size) => {
  switch (size) {
    case "mobile":
      return (
        <svg className="headerTitleMobile">
          <path d="M 17.578 30.273 L 16.602 30.273 L 8.789 22.461 L 17.578 22.461 A 5.636 5.636 0 0 0 21.665 20.795 A 6.854 6.854 0 0 0 21.719 20.742 A 5.637 5.637 0 0 0 23.437 16.677 A 6.78 6.78 0 0 0 23.438 16.602 A 5.636 5.636 0 0 0 21.772 12.515 A 6.854 6.854 0 0 0 21.719 12.461 A 5.637 5.637 0 0 0 17.654 10.743 A 6.78 6.78 0 0 0 17.578 10.742 L 7.813 10.742 L 7.813 33.203 L 0 25.391 L 0 2.93 L 17.578 2.93 A 13.157 13.157 0 0 1 27.121 6.809 A 15.902 15.902 0 0 1 27.246 6.934 A 13.157 13.157 0 0 1 31.249 16.425 A 15.915 15.915 0 0 1 31.25 16.602 A 13.157 13.157 0 0 1 27.37 26.144 A 15.902 15.902 0 0 1 27.246 26.27 A 13.157 13.157 0 0 1 17.755 30.272 A 15.915 15.915 0 0 1 17.578 30.273 Z" />
          <path d="M 41.016 20.508 L 41.016 22.461 L 64.453 22.461 L 64.453 30.273 L 33.203 30.273 L 33.203 2.93 L 68.359 2.93 L 60.547 10.742 L 41.016 10.742 L 41.016 12.695 L 60.547 12.695 L 60.547 20.508 L 41.016 20.508 Z" />
          <path d="M 66.406 10.742 L 66.406 7.813 L 74.219 0 L 74.219 10.742 A 18.298 18.298 0 0 0 74.422 13.552 Q 74.664 15.109 75.196 16.384 A 9.316 9.316 0 0 0 75.937 17.813 Q 77.656 20.508 82.031 22.266 A 19.346 19.346 0 0 0 84.506 21.075 Q 86.943 19.666 88.125 17.813 A 10.12 10.12 0 0 0 89.359 14.917 Q 89.692 13.645 89.796 12.149 A 20.246 20.246 0 0 0 89.844 10.742 L 89.844 2.93 L 97.656 2.93 L 97.656 10.742 A 23.817 23.817 0 0 1 96.995 16.496 A 17.511 17.511 0 0 1 93.652 23.34 Q 89.648 28.32 82.031 30.273 A 26.582 26.582 0 0 1 76.632 28.286 A 18.623 18.623 0 0 1 70.41 23.34 A 17.826 17.826 0 0 1 66.807 15.298 A 24.799 24.799 0 0 1 66.406 10.742 Z" />
          <path d="M 107.422 0 L 107.422 30.273 L 99.609 30.273 L 99.609 7.813 L 107.422 0 Z" />
          <path d="M 116.855 2.93 L 132.813 18.887 L 132.813 0 L 140.625 7.813 L 140.625 30.273 L 133.145 30.273 L 117.188 14.316 L 117.188 33.203 L 109.375 25.391 L 109.375 2.93 L 116.855 2.93 Z" />
          <path d="M 173.828 16.602 L 173.828 0 L 181.641 7.813 L 181.641 16.602 A 13.157 13.157 0 0 1 177.761 26.144 A 15.902 15.902 0 0 1 177.637 26.27 A 13.157 13.157 0 0 1 168.145 30.272 A 15.915 15.915 0 0 1 167.969 30.273 L 164.063 30.273 A 13.157 13.157 0 0 1 154.52 26.394 A 15.902 15.902 0 0 1 154.395 26.27 A 13.157 13.157 0 0 1 150.392 16.778 A 15.915 15.915 0 0 1 150.391 16.602 L 150.391 14.648 L 158.203 14.648 L 158.203 16.602 A 5.636 5.636 0 0 0 159.869 20.688 A 6.854 6.854 0 0 0 159.922 20.742 A 5.637 5.637 0 0 0 163.987 22.461 A 6.78 6.78 0 0 0 164.063 22.461 L 167.969 22.461 A 5.636 5.636 0 0 0 172.055 20.795 A 6.854 6.854 0 0 0 172.109 20.742 A 5.637 5.637 0 0 0 173.828 16.677 A 6.78 6.78 0 0 0 173.828 16.602 Z" />
          <path d="M 183.594 16.602 L 183.594 7.813 L 191.406 0 L 191.406 16.602 A 5.636 5.636 0 0 0 193.072 20.688 A 6.854 6.854 0 0 0 193.125 20.742 A 5.637 5.637 0 0 0 197.19 22.461 A 6.78 6.78 0 0 0 197.266 22.461 L 201.172 22.461 A 5.636 5.636 0 0 0 205.259 20.795 A 6.854 6.854 0 0 0 205.312 20.742 A 5.637 5.637 0 0 0 207.031 16.677 A 6.78 6.78 0 0 0 207.031 16.602 L 207.031 2.93 L 214.844 2.93 L 214.844 16.602 A 13.157 13.157 0 0 1 210.964 26.144 A 15.902 15.902 0 0 1 210.84 26.27 A 13.157 13.157 0 0 1 201.348 30.272 A 15.915 15.915 0 0 1 201.172 30.273 L 197.266 30.273 A 13.157 13.157 0 0 1 187.723 26.394 A 15.902 15.902 0 0 1 187.598 26.27 A 13.157 13.157 0 0 1 183.595 16.778 A 15.915 15.915 0 0 1 183.594 16.602 Z" />
          <path d="M 224.609 20.508 L 224.609 22.461 L 248.047 22.461 L 248.047 30.273 L 216.797 30.273 L 216.797 2.93 L 251.953 2.93 L 244.141 10.742 L 224.609 10.742 L 224.609 12.695 L 244.141 12.695 L 244.141 20.508 L 224.609 20.508 Z" />
          <path d="M 250 2.93 L 285.156 2.93 L 277.344 10.742 L 269.531 10.742 L 269.531 25.391 L 261.719 33.203 L 261.719 10.742 L 250 10.742 L 250 2.93 Z" />
          <path d="M 283.203 2.93 L 318.359 2.93 L 310.547 10.742 L 302.734 10.742 L 302.734 25.391 L 294.922 33.203 L 294.922 10.742 L 283.203 10.742 L 283.203 2.93 Z" />
        </svg>
      );
    case "tablet":
      return (
        <svg className="headerTitle">
          <path d="M 21.973 37.842 L 20.752 37.842 L 10.986 28.076 L 21.973 28.076 A 7.046 7.046 0 0 0 27.081 25.994 A 8.569 8.569 0 0 0 27.148 25.928 A 7.047 7.047 0 0 0 29.296 20.846 A 8.476 8.476 0 0 0 29.297 20.752 A 7.046 7.046 0 0 0 27.215 15.644 A 8.569 8.569 0 0 0 27.148 15.576 A 7.047 7.047 0 0 0 22.067 13.428 A 8.476 8.476 0 0 0 21.973 13.428 L 9.766 13.428 L 9.766 41.504 L 0 31.738 L 0 3.662 L 21.973 3.662 A 16.447 16.447 0 0 1 33.901 8.512 A 19.879 19.879 0 0 1 34.058 8.667 A 16.446 16.446 0 0 1 39.061 20.531 A 19.893 19.893 0 0 1 39.062 20.752 A 16.447 16.447 0 0 1 34.213 32.68 A 19.879 19.879 0 0 1 34.058 32.837 A 16.446 16.446 0 0 1 22.193 37.841 A 19.893 19.893 0 0 1 21.973 37.842 Z" />
          <path d="M 51.27 25.635 L 51.27 28.076 L 80.566 28.076 L 80.566 37.842 L 41.504 37.842 L 41.504 3.662 L 85.449 3.662 L 75.684 13.428 L 51.27 13.428 L 51.27 15.869 L 75.684 15.869 L 75.684 25.635 L 51.27 25.635 Z" />
          <path d="M 83.008 13.428 L 83.008 9.766 L 92.773 0 L 92.773 13.428 A 22.873 22.873 0 0 0 93.027 16.939 Q 93.33 18.886 93.995 20.48 A 11.646 11.646 0 0 0 94.922 22.266 Q 96.573 24.854 100.183 26.751 A 25.544 25.544 0 0 0 102.539 27.832 A 24.183 24.183 0 0 0 105.633 26.344 Q 108.679 24.582 110.156 22.266 A 12.65 12.65 0 0 0 111.698 18.646 Q 112.115 17.056 112.245 15.186 A 25.307 25.307 0 0 0 112.305 13.428 L 112.305 3.662 L 122.07 3.662 L 122.07 13.428 A 29.771 29.771 0 0 1 121.244 20.62 A 21.888 21.888 0 0 1 117.065 29.175 Q 112.061 35.4 102.539 37.842 A 33.227 33.227 0 0 1 95.79 35.357 A 23.278 23.278 0 0 1 88.013 29.175 Q 83.008 22.949 83.008 13.428 Z" />
          <path d="M 134.277 0 L 134.277 37.842 L 124.512 37.842 L 124.512 9.766 L 134.277 0 Z" />
          <path d="M 146.069 3.662 L 166.016 23.608 L 166.016 0 L 175.781 9.766 L 175.781 37.842 L 166.431 37.842 L 146.484 17.896 L 146.484 41.504 L 136.719 31.738 L 136.719 3.662 L 146.069 3.662 Z" />
          <path d="M 217.285 20.752 L 217.285 0 L 227.051 9.766 L 227.051 20.752 A 16.447 16.447 0 0 1 222.201 32.68 A 19.879 19.879 0 0 1 222.046 32.837 A 16.446 16.446 0 0 1 210.182 37.841 A 19.893 19.893 0 0 1 209.961 37.842 L 205.078 37.842 A 16.447 16.447 0 0 1 193.15 32.992 A 19.879 19.879 0 0 1 192.993 32.837 A 16.446 16.446 0 0 1 187.99 20.973 A 19.893 19.893 0 0 1 187.988 20.752 L 187.988 18.311 L 197.754 18.311 L 197.754 20.752 A 7.046 7.046 0 0 0 199.836 25.86 A 8.569 8.569 0 0 0 199.902 25.928 A 7.047 7.047 0 0 0 204.984 28.076 A 8.476 8.476 0 0 0 205.078 28.076 L 209.961 28.076 A 7.046 7.046 0 0 0 215.069 25.994 A 8.569 8.569 0 0 0 215.137 25.928 A 7.047 7.047 0 0 0 217.285 20.846 A 8.476 8.476 0 0 0 217.285 20.752 Z" />
          <path d="M 229.492 20.752 L 229.492 9.766 L 239.258 0 L 239.258 20.752 A 7.046 7.046 0 0 0 241.34 25.86 A 8.569 8.569 0 0 0 241.406 25.928 A 7.047 7.047 0 0 0 246.488 28.076 A 8.476 8.476 0 0 0 246.582 28.076 L 251.465 28.076 A 7.046 7.046 0 0 0 256.573 25.994 A 8.569 8.569 0 0 0 256.641 25.928 A 7.047 7.047 0 0 0 258.789 20.846 A 8.476 8.476 0 0 0 258.789 20.752 L 258.789 3.662 L 268.555 3.662 L 268.555 20.752 A 16.447 16.447 0 0 1 263.705 32.68 A 19.879 19.879 0 0 1 263.55 32.837 A 16.446 16.446 0 0 1 251.686 37.841 A 19.893 19.893 0 0 1 251.465 37.842 L 246.582 37.842 A 16.447 16.447 0 0 1 234.654 32.992 A 19.879 19.879 0 0 1 234.497 32.837 A 16.446 16.446 0 0 1 229.493 20.973 A 19.893 19.893 0 0 1 229.492 20.752 Z" />
          <path d="M 280.762 25.635 L 280.762 28.076 L 310.059 28.076 L 310.059 37.842 L 270.996 37.842 L 270.996 3.662 L 314.941 3.662 L 305.176 13.428 L 280.762 13.428 L 280.762 15.869 L 305.176 15.869 L 305.176 25.635 L 280.762 25.635 Z" />
          <path d="M 312.5 3.662 L 356.445 3.662 L 346.68 13.428 L 336.914 13.428 L 336.914 31.738 L 327.148 41.504 L 327.148 13.428 L 312.5 13.428 L 312.5 3.662 Z" />
          <path d="M 354.004 3.662 L 397.949 3.662 L 388.184 13.428 L 378.418 13.428 L 378.418 31.738 L 368.652 41.504 L 368.652 13.428 L 354.004 13.428 L 354.004 3.662 Z" />
        </svg>
      );
    default:
      return (
        <svg className="headerTitle">
          <path d="M 43.945 75.684 L 41.504 75.684 L 21.973 56.152 L 43.945 56.152 A 14.091 14.091 0 0 0 54.162 51.989 A 17.129 17.129 0 0 0 54.297 51.855 A 14.094 14.094 0 0 0 58.593 41.693 A 16.946 16.946 0 0 0 58.594 41.504 A 14.091 14.091 0 0 0 54.43 31.287 A 17.129 17.129 0 0 0 54.297 31.152 A 14.094 14.094 0 0 0 44.134 26.857 A 16.946 16.946 0 0 0 43.945 26.855 L 19.531 26.855 L 19.531 83.008 L 0 63.477 L 0 7.324 L 43.945 7.324 A 32.893 32.893 0 0 1 67.801 17.024 A 39.758 39.758 0 0 1 68.115 17.334 Q 78.125 27.344 78.125 41.504 A 32.893 32.893 0 0 1 68.426 65.36 A 39.758 39.758 0 0 1 68.115 65.674 Q 58.105 75.684 43.945 75.684 Z" />
          <path d="M 102.539 51.27 L 102.539 56.152 L 161.133 56.152 L 161.133 75.684 L 83.008 75.684 L 83.008 7.324 L 170.898 7.324 L 151.367 26.855 L 102.539 26.855 L 102.539 31.738 L 151.367 31.738 L 151.367 51.27 L 102.539 51.27 Z" />
          <path d="M 166.016 26.855 L 166.016 19.531 L 185.547 0 L 185.547 26.855 Q 185.547 35.101 187.989 40.96 A 23.291 23.291 0 0 0 189.844 44.531 A 22.242 22.242 0 0 0 194.762 49.889 Q 198.84 53.158 205.078 55.664 A 48.365 48.365 0 0 0 211.266 52.689 Q 217.358 49.164 220.313 44.531 Q 224.609 37.793 224.609 26.855 L 224.609 7.324 L 244.141 7.324 L 244.141 26.855 A 59.542 59.542 0 0 1 242.489 41.24 A 43.776 43.776 0 0 1 234.131 58.35 A 46.648 46.648 0 0 1 218.334 70.836 A 66.697 66.697 0 0 1 205.078 75.684 A 66.455 66.455 0 0 1 191.579 70.714 A 46.556 46.556 0 0 1 176.025 58.35 A 44.565 44.565 0 0 1 167.018 38.246 A 61.998 61.998 0 0 1 166.016 26.855 Z" />
          <path d="M 268.555 0 L 268.555 75.684 L 249.023 75.684 L 249.023 19.531 L 268.555 0 Z" />
          <path d="M 292.139 7.324 L 332.031 47.217 L 332.031 0 L 351.563 19.531 L 351.563 75.684 L 332.861 75.684 L 292.969 35.791 L 292.969 83.008 L 273.438 63.477 L 273.438 7.324 L 292.139 7.324 Z" />
          <path d="M 434.57 41.504 L 434.57 0 L 454.102 19.531 L 454.102 41.504 A 32.893 32.893 0 0 1 444.402 65.36 A 39.758 39.758 0 0 1 444.092 65.674 Q 434.082 75.684 419.922 75.684 L 410.156 75.684 A 32.893 32.893 0 0 1 386.3 65.984 A 39.758 39.758 0 0 1 385.986 65.674 Q 375.977 55.664 375.977 41.504 L 375.977 36.621 L 395.508 36.621 L 395.508 41.504 A 14.091 14.091 0 0 0 399.671 51.721 A 17.129 17.129 0 0 0 399.805 51.855 A 14.094 14.094 0 0 0 409.967 56.151 A 16.946 16.946 0 0 0 410.156 56.152 L 419.922 56.152 A 14.091 14.091 0 0 0 430.139 51.989 A 17.129 17.129 0 0 0 430.273 51.855 A 14.094 14.094 0 0 0 434.569 41.693 A 16.946 16.946 0 0 0 434.57 41.504 Z" />
          <path d="M 458.984 41.504 L 458.984 19.531 L 478.516 0 L 478.516 41.504 A 14.091 14.091 0 0 0 482.679 51.721 A 17.129 17.129 0 0 0 482.812 51.855 A 14.094 14.094 0 0 0 492.975 56.151 A 16.946 16.946 0 0 0 493.164 56.152 L 502.93 56.152 A 14.091 14.091 0 0 0 513.147 51.989 A 17.129 17.129 0 0 0 513.281 51.855 A 14.094 14.094 0 0 0 517.577 41.693 A 16.946 16.946 0 0 0 517.578 41.504 L 517.578 7.324 L 537.109 7.324 L 537.109 41.504 A 32.893 32.893 0 0 1 527.41 65.36 A 39.758 39.758 0 0 1 527.1 65.674 Q 517.09 75.684 502.93 75.684 L 493.164 75.684 A 32.893 32.893 0 0 1 469.308 65.984 A 39.758 39.758 0 0 1 468.994 65.674 Q 458.984 55.664 458.984 41.504 Z" />
          <path d="M 561.523 51.27 L 561.523 56.152 L 620.117 56.152 L 620.117 75.684 L 541.992 75.684 L 541.992 7.324 L 629.883 7.324 L 610.352 26.855 L 561.523 26.855 L 561.523 31.738 L 610.352 31.738 L 610.352 51.27 L 561.523 51.27 Z" />
          <path d="M 625 7.324 L 712.891 7.324 L 693.359 26.855 L 673.828 26.855 L 673.828 63.477 L 654.297 83.008 L 654.297 26.855 L 625 26.855 L 625 7.324 Z" />
          <path d="M 708.008 7.324 L 795.898 7.324 L 776.367 26.855 L 756.836 26.855 L 756.836 63.477 L 737.305 83.008 L 737.305 26.855 L 708.008 26.855 L 708.008 7.324 Z" />
        </svg>
      );
  }
};
// EXPORTS
export { randomBetween, checkDimensions, initialSettings, generateTitle };
