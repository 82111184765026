// IMPORTS
import React, { Fragment, useEffect, useState } from "react";
import anime from "animejs";
import { FaRegFileAlt, FaRegFolderOpen, FaCode } from "react-icons/fa";
import { generateTitle } from "../helpers/methods";
// COMPONENT
const Header = ({ props }) => {
  // STATE
  const { state, setState } = props;
  const {
    loaded: { initial, three },
    nav: { page, hamburger },
    settings: { animate, isMobile, isTablet },
  } = state;
  const [animationClass, setAnimationClass] = useState("headerFadeIn");
  // LIFE CYCLE
  useEffect(() => {
    if (animate) {
      anime({
        targets: isMobile ? ".headerTitleMobile path" : ".headerTitle path",
        strokeDashoffset: [anime.setDashoffset, 0],
        easing: "easeInOutSine",
        duration: 2000,
        loop: false,
        complete: () => {
          setState((currentState) => {
            return {
              ...currentState,
              loaded: { ...currentState.loaded, initial: true },
              nav: {
                ...currentState.nav,
                page:
                  !initial && page === null
                    ? "Projects"
                    : currentState.nav.page,
              },
              settings: {
                ...currentState.settings,
                animate: false,
              },
            };
          });
        },
      });
      animationClass !== "" &&
        setTimeout(() => {
          setAnimationClass("");
        }, 3000);
    }
    anime({
      targets: ".headerResumeButton .headerButtonUnderline",
      left: page !== "Resume" ? "-400%" : "-2%",
      duration: 2000,
      easing: "spring",
    });
    anime({
      targets: ".headerCodeButton .headerButtonUnderline",
      right: page !== "Code" ? "-400%" : "0%",
      duration: 2000,
      easing: "spring",
    });
    anime({
      targets: ".headerProjectsButton .headerButtonUnderline",
      right: page !== "Projects" ? "-400%" : "0%",
      duration: 2000,
      easing: "spring",
    });
  }, [animate, isMobile, setState, initial, animationClass, page]);
  // GENERATE BUTTON
  const generateButton = (title, icon) => {
    return (
      <button
        className={`header${title}Button ${animationClass}`}
        onClick={() => {
          let pageIsSelected = page === title;
          setState({
            ...state,
            nav: {
              ...state.nav,
              page: pageIsSelected ? null : title,
              hamburger: isMobile ? false : hamburger,
            },
          });
        }}
      >
        {icon && icon}
        {title}
        <span
          className="headerButtonUnderline"
          style={{
            left: title === "Resume" && "-400%",
            right: (title === "Code" || title === "Projects") && "-400%",
          }}
        />
      </button>
    );
  };
  // RENDER
  return (
    <div
      className="headerContainer"
      style={{
        display: !three && "none",
      }}
    >
      {/* RESUME BUTTON */}
      {!isMobile && (
        <div className="headerButtonsContainer">{generateButton("Resume")}</div>
      )}
      {/* TITLE */}
      {window.innerWidth <= 300 ? (
        <p className="headerTitleMobileSmall">Devin Juett</p>
      ) : (
        generateTitle(
          isMobile
            ? "mobile"
            : isTablet || window.innerWidth <= 1250
            ? "tablet"
            : "desktop"
        )
      )}
      {/* CODE & PROJECTS BUTTON */}
      {!isMobile && (
        <div className="headerButtonsContainer">
          {generateButton("Code")}
          {generateButton("Projects")}
        </div>
      )}
      {/* HAMBURGER */}
      {isMobile && (
        <Fragment>
          <svg
            className={
              hamburger
                ? `headerHamburgerActive ${animationClass}`
                : `headerHamburger ${animationClass}`
            }
            viewBox="0 0 100 100"
            onClick={() => {
              setState({
                ...state,
                nav: { ...state.nav, hamburger: !hamburger },
              });
            }}
          >
            <path
              className="line top"
              d="m 30,33 h 40 c 0,0 9.044436,-0.654587 9.044436,-8.508902 0,-7.854315 -8.024349,-11.958003 -14.89975,-10.85914 -6.875401,1.098863 -13.637059,4.171617 -13.637059,16.368042 v 40"
            />
            <path className="line" d="m 30,50 h 40" />
            <path
              className="line bottom"
              d="m 30,67 h 40 c 12.796276,0 15.357889,-11.717785 15.357889,-26.851538 0,-15.133752 -4.786586,-27.274118 -16.667516,-27.274118 -11.88093,0 -18.499247,6.994427 -18.435284,17.125656 l 0.252538,40"
            />
          </svg>
          <div
            className="headerHamburgerContent"
            style={{
              top: hamburger ? "4rem" : "100vh",
            }}
          >
            {generateButton(
              "Resume",
              <FaRegFileAlt className="headerButtonIcon" />
            )}
            {generateButton("Code", <FaCode className="headerButtonIcon" />)}
            {generateButton(
              "Projects",
              <FaRegFolderOpen className="headerButtonIcon" />
            )}
          </div>
        </Fragment>
      )}
    </div>
  );
};
// EXPORT
export default Header;
