// IMPORTS
import React from "react";
import * as Icon from "react-icons/si";
import { GrStripe } from "react-icons/gr";
// PROJECTS
const projects = [
  {
    title: "30 Day Tracker",
    image: require("../assets/snaps/30daytracker.jpeg"),
    url: "https://30daytracker.com",
    stack: [
      { title: "React", icon: <Icon.SiReact className="react" /> },
      { title: "Redux", icon: <Icon.SiRedux className="redux" /> },
      { title: "SCSS", icon: <Icon.SiSass className="sass" /> },
      { title: "Firebase", icon: <Icon.SiFirebase className="firebase" /> },
    ],
    show: {
      mobile: true,
      tablet: true,
      helperText: false,
    },
  },
  {
    title: "Calgary Cakery",
    image: require("../assets/snaps/calgarycakery.jpeg"),
    url: "https://calgarycakery.com",
    stack: [
      { title: "React", icon: <Icon.SiReact className="react" /> },
      { title: "SCSS", icon: <Icon.SiSass className="sass" /> },
      { title: "Node.js", icon: <Icon.SiNodedotjs className="nodejs" /> },
      { title: "Stripe", icon: <GrStripe className="stripe" /> },
      {
        title: "Coinbase",
        icon: (
          <img
            className="coinbase"
            alt="Coinbase"
            src={require("../assets/icons/coinbase.png")}
          />
        ),
      },
      { title: "GraphQL", icon: <Icon.SiGraphql className="graphql" /> },
      { title: "Wordpress", icon: <Icon.SiWordpress className="wordpress" /> },
    ],
    show: {
      mobile: true,
      tablet: true,
      helperText: false,
    },
  },
  {
    title: "Stacker 3D",
    image: require("../assets/snaps/stacker3D.jpeg"),
    url: "https://stacker3d.kingdroplet.com",
    stack: [
      { title: "Three.js", icon: <Icon.SiThreedotjs className="three" /> },
      { title: "React", icon: <Icon.SiReact className="react" /> },
      { title: "SCSS", icon: <Icon.SiSass className="sass" /> },
    ],
    show: {
      mobile: true,
      tablet: true,
      helperText: false,
    },
  },
  {
    title: "Whack-A-Mole",
    image: require("../assets/snaps/whack-a-mole.jpeg"),
    url: "https://whackamole.kingdroplet.com",
    stack: [
      { title: "React Native", icon: <Icon.SiReact className="react" /> },
    ],
    show: {
      mobile: true,
      tablet: true,
      helperText: false,
    },
  },
  {
    title: "Wobble Hops",
    image: require("../assets/snaps/wobblehops.jpeg"),
    url: "https://wobblehops.com",
    stack: [
      { title: "React", icon: <Icon.SiReact className="react" /> },
      { title: "SCSS", icon: <Icon.SiSass className="sass" /> },
    ],
    show: {
      mobile: false,
      tablet: false,
      helperText: false,
    },
  },
  {
    title: "Dodger",
    image: require("../assets/snaps/dodger.jpeg"),
    url: "https://dodger.kingdroplet.com",
    stack: [
      { title: "Unity", icon: <Icon.SiUnity className="unity" /> },
      { title: "C#", icon: <Icon.SiCsharp className="cSharp" /> },
    ],
    show: {
      mobile: true,
      tablet: true,
      helperText: true,
    },
  },
  {
    title: "Top Down Shooter",
    image: require("../assets/snaps/top-down-shooter.jpeg"),
    url: "https://topdownshooter.kingdroplet.com",
    stack: [
      { title: "Unity", icon: <Icon.SiUnity className="unity" /> },
      { title: "C#", icon: <Icon.SiCsharp className="cSharp" /> },
    ],
    show: {
      mobile: false,
      tablet: false,
      helperText: false,
    },
  },
  {
    title: "Traffic Run",
    image: require("../assets/snaps/traffic-run.jpeg"),
    url: "https://trafficrun.kingdroplet.com",
    stack: [
      { title: "Three.js", icon: <Icon.SiThreedotjs className="three" /> },
      { title: "React", icon: <Icon.SiReact className="react" /> },
      { title: "SCSS", icon: <Icon.SiSass className="sass" /> },
    ],
    show: {
      mobile: false,
      tablet: false,
      helperText: false,
    },
  },
  {
    title: "Spitfire",
    image: require("../assets/snaps/spitfire.jpeg"),
    url: "https://spitfire.kingdroplet.com",
    stack: [
      { title: "Three.js", icon: <Icon.SiThreedotjs className="three" /> },
      { title: "React", icon: <Icon.SiReact className="react" /> },
      { title: "SCSS", icon: <Icon.SiSass className="sass" /> },
    ],
    show: {
      mobile: false,
      tablet: false,
      helperText: false,
    },
  },
  {
    title: "Flappy Bird",
    image: require("../assets/snaps/flappy-bird.jpeg"),
    url: "https://flappybird.kingdroplet.com",
    stack: [
      { title: "React Native", icon: <Icon.SiReact className="react" /> },
    ],
    show: {
      mobile: true,
      tablet: true,
      helperText: false,
    },
  },
  {
    title: "Minesweeper",
    image: require("../assets/snaps/mine-sweeper.jpeg"),
    url: "https://minesweeper.kingdroplet.com",
    stack: [
      { title: "React Native", icon: <Icon.SiReact className="react" /> },
    ],
    show: {
      mobile: true,
      tablet: true,
      helperText: false,
    },
  },
  {
    title: "Snake",
    image: require("../assets/snaps/snake.jpeg"),
    url: "https://snake.kingdroplet.com",
    stack: [
      { title: "React Native", icon: <Icon.SiReact className="react" /> },
    ],
    show: {
      mobile: true,
      tablet: true,
      helperText: false,
    },
  },
];
// EXPORT
export { projects };
