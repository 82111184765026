// IMPORTS
import "./styles/App.scss";
import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { checkDimensions, initialSettings } from "./helpers/methods";
import Header from "./components/Header";
import Three from "./components/Three";
import Code from "./pages/Code";
import Projects from "./pages/Projects";
import Resume from "./pages/Resume";
import Website from "./pages/Website";
// COMPONENT
const App = () => {
  // STATE
  const [state, setState] = useState({
    loaded: { initial: false, three: false },
    nav: { page: null, project: null, hamburger: false },
    settings: initialSettings(),
  });
  // UPDATE SETTINGS
  const updateSettings = (newSettings) => {
    setState((currentState) => {
      return {
        ...currentState,
        settings: { ...currentState.settings, ...newSettings },
      };
    });
  };
  // LIFE CYCLE
  useEffect(() => {
    window.addEventListener("resize", () => {
      updateSettings(checkDimensions(false));
    });
    window.addEventListener("orientationchange", () => {
      updateSettings(checkDimensions(true));
    });
  }, []);
  // RENDER
  return (
    <div className="appContainer">
      {/* HEADER */}
      <Header props={{ state, setState }} />
      {/* THREE */}
      <Three props={{ state, setState }} />
      {/* CODE */}
      <Code props={{ state, setState }} />
      {/* PROJECTS */}
      <Projects props={{ state, setState }} />
      {/* RESUME */}
      <Resume props={{ state, setState }} />
      {/* WEBSITE */}
      <Website props={{ state, setState }} />
    </div>
  );
};
// INITIALIZE
ReactDOM.render(<App />, document.getElementById("root"));
