// IMPORTS
import React, { useRef, useEffect } from "react";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { randomBetween } from "../helpers/methods";
// COMPONENT
const Three = ({ props }) => {
  // STATE
  const { setState } = props;
  const mount = useRef(null);
  // LIFE CYCLE
  useEffect(() => {
    // VARIABLES
    let height = window.innerHeight;
    let width = window.innerWidth;
    let time = new THREE.Clock();
    // CAMERA
    const camera = new THREE.PerspectiveCamera(
      75,
      width / height,
      0.0001,
      4000
    );
    // SCENE
    const scene = new THREE.Scene();
    // RENDERER
    const renderer = new THREE.WebGLRenderer({ alpha: true, antialias: true });
    renderer.setSize(width, height);
    renderer.setClearColor(0xffffff, 0);
    mount.current.appendChild(renderer.domElement);
    // CONTROLS
    const controls = new OrbitControls(camera, renderer.domElement);
    controls.enableDamping = true;
    controls.enablePan = false;
    controls.enableZoom = false;
    controls.maxAzimuthAngle = THREE.Math.degToRad(0 + 5);
    controls.minAzimuthAngle = THREE.Math.degToRad(0 - 5);
    controls.maxPolarAngle = THREE.Math.degToRad(90 + 5);
    controls.minPolarAngle = THREE.Math.degToRad(90 - 5);
    controls.rotateSpeed = 0.1;
    camera.position.set(0, 0, 500);
    controls.update();
    // MANAGER
    const manager = new THREE.LoadingManager();
    manager.onLoad = () => {
      setState((currentState) => {
        return {
          ...currentState,
          loaded: { ...currentState.loaded, three: true },
        };
      });
    };
    // STARS
    const totalStars = 50000;
    let starPositions = [];
    let starSpeeds = [];
    const starRange = 2000;
    const starGeometry = new THREE.BufferGeometry();
    const starMaterial = new THREE.PointsMaterial({
      transparent: true,
      map: new THREE.TextureLoader(manager).load(
        require("../assets/sprites/star.png")
      ),
    });
    for (let i = 0; i < totalStars; i++) {
      starPositions.push(
        THREE.MathUtils.randFloatSpread(starRange),
        THREE.MathUtils.randFloatSpread(starRange),
        THREE.MathUtils.randFloatSpread(starRange / 2)
      );
      starSpeeds.push(randomBetween(0.2, 0.5));
    }
    starGeometry.setAttribute(
      "position",
      new THREE.Float32BufferAttribute(starPositions, 3)
    );
    const stars = new THREE.Points(starGeometry, starMaterial);
    scene.add(stars);
    // HANDLE EVENTS
    const handleEvents = () => {
      window.addEventListener("resize", () => {
        camera.aspect = window.innerWidth / window.innerHeight;
        camera.updateProjectionMatrix();
        renderer.setSize(window.innerWidth, window.innerHeight);
      });
      window.addEventListener("orientationchange", () => {
        camera.aspect = window.innerWidth / window.innerHeight;
        camera.updateProjectionMatrix();
        renderer.setSize(window.innerWidth, window.innerHeight);
      });
    };
    // LOOP
    const loop = () => {
      stars.rotation.z = time.getElapsedTime() * 0.025;
      let currentStarPositions = stars.geometry.getAttribute("position");
      for (let i = 0; i < totalStars; i++) {
        let z = currentStarPositions.getZ(i);
        let speed = starSpeeds[i];
        z > 500 ? (z = -500) : (z += speed);
        currentStarPositions.setZ(i, z);
      }
      currentStarPositions.needsUpdate = true;
      controls.update();
      renderer.render(scene, camera);
    };
    // INITIALIZE
    handleEvents();
    renderer.setAnimationLoop(loop);
  }, [setState]);
  // RENDER
  return <div ref={mount} className="threeContainer" />;
};
// EXPORT
export default Three;
