// IMPORTS
import React, { useState, useRef, useEffect } from "react";
import anime from "animejs";
import { FaAngleRight, FaAngleDown } from "react-icons/fa";
import { VscCollapseAll, VscCloseAll, VscClose } from "react-icons/vsc";
import { AiOutlineFullscreen, AiOutlineFullscreenExit } from "react-icons/ai";
import { code } from "../helpers/code";
import css from "../styles/modules/_exports.scss";
// COMPONENT
const Code = ({ props }) => {
  // STATE
  const { state } = props;
  const {
    nav: { page },
    settings: { isMobile, isDesktop },
  } = state;
  const [header, setHeader] = useState({
    tabs: [],
    selected: "",
    disabled: "",
  });
  const [nav, setNav] = useState({
    0: [],
    1: [],
    2: [],
  });
  const [path, setPath] = useState({
    directory: "",
    parents: [],
    page: "",
  });
  const [snippet, setSnippet] = useState({
    width: 0,
    offset: 0,
  });
  const [fullscreen, setFullscreen] = useState(false);
  const pageExists = path.page !== "";
  let showing = useRef(false);
  // LIFE CYCLE
  useEffect(() => {
    anime({
      targets: ".codeContainer",
      right: showing.current
        ? page === "Resume" || page === "Website" || page == null
          ? "-100%"
          : page === "Projects" && "100%"
        : page === "Code" && "0%",
      duration: 400,
      easing: "linear",
    });
    showing.current &&
      page === "Projects" &&
      setTimeout(() => {
        anime({
          targets: ".codeContainer",
          right: "-100%",
          duration: 0,
        });
      }, 500);
    page === "Code" ? (showing.current = true) : (showing.current = false);
    if (isMobile) {
      if (pageExists) {
        setFullscreen(true);
      } else {
        !fullscreen && setPath({ directory: "", parents: [], page: "" });
      }
    }
  }, [page, isMobile, pageExists, fullscreen]);
  // GENERATE FILE
  const generateFile = ({ title, level, icon, directory, parents }) => {
    const offset = level * 5;
    const isJS = title.split(".")[1] === "js";
    const selected = path.directory === directory && path.page === title;
    return (
      <div key={title} className="codeNavFile">
        <button
          className={selected ? "codeNavButtonSelected" : "codeNavButton"}
          style={{
            paddingLeft: `${offset}%`,
          }}
          onClick={() => {
            const tabExists =
              header.tabs.filter(
                (tab) =>
                  `${tab.directory}-${tab.title}` === `${directory}-${title}`
              ).length > 0;
            if (!tabExists) {
              setHeader({
                ...header,
                tabs: [{ directory, parents, title, icon }, ...header.tabs],
              });
            }
            setPath({ directory, parents, page: title });
            isMobile && setFullscreen(true);
          }}
        >
          <img
            className="codeNavIcon"
            style={{
              height: isJS && "1rem",
              marginLeft: `${offset}%`,
            }}
            alt={`${title} Icon`}
            src={icon}
          />
          <p className="codeNavText">{title.replace("-", "")}</p>
        </button>
      </div>
    );
  };
  // GENRATE DIRECTORY
  const generateDirectory = ({
    title,
    level,
    icons: { open, closed },
    directory,
    parents,
    children,
  }) => {
    const hasChildren = children !== null;
    const offset = level * 5;
    const value = directory === title ? title : `${directory}-${title}`;
    const selected = nav[level].includes(value);
    return (
      <div
        key={title}
        className="codeNavDirectory"
        style={{
          height: nav[level].includes(value) && "auto",
        }}
      >
        <button
          className="codeNavButton"
          style={{
            paddingLeft: `${offset}%`,
          }}
          onClick={() => {
            const updatedLevel = nav[level].includes(value)
              ? nav[level].filter((item) => item !== value)
              : [...nav[level], value];
            setNav({ ...nav, [level]: updatedLevel });
          }}
        >
          {selected ? (
            <FaAngleDown className="codeNavIcon" />
          ) : (
            <FaAngleRight className="codeNavIcon" />
          )}
          <img
            className="codeNavIcon"
            alt="Components Icon"
            src={nav[level].includes(value) ? open : closed}
          />
          <p className="codeNavText">{title}</p>
        </button>
        {hasChildren &&
          children.map((child) => {
            const childIsSting = typeof child === "string";
            const childIsArray = Array.isArray(child);
            if (childIsSting) {
              const isJS = child.split(".")[1] === "js";
              return generateFile({
                title: child,
                level: level + 1,
                icon:
                  (title === "styles" || title === "modules") && !isJS
                    ? require(`../assets/code/_icons/scss.png`)
                    : require(`../assets/code/_icons/js.png`),
                directory,
                parents: [
                  ...parents,
                  {
                    title: child,
                    icon:
                      title === "styles" || title === "modules"
                        ? require(`../assets/code/_icons/scss.png`)
                        : require(`../assets/code/_icons/js.png`),
                  },
                ],
              });
            } else {
              return generateDirectory({
                title: childIsArray ? "modules" : child.title,
                level: childIsArray ? level + 1 : 1,
                icons: childIsArray
                  ? {
                      open: require(`../assets/code/_icons/modules-open.png`),
                      closed: require(`../assets/code/_icons/modules-closed.png`),
                    }
                  : {
                      open: require(`../assets/code/_icons/${child.title}-open.png`),
                      closed: require(`../assets/code/_icons/${child.title}-closed.png`),
                    },
                directory,
                parents: [
                  ...parents,
                  {
                    title: childIsArray ? "modules" : child.title,
                    icon: childIsArray
                      ? require(`../assets/code/_icons/modules-open.png`)
                      : require(`../assets/code/_icons/${child.title}-open.png`),
                  },
                ],
                children: childIsArray ? child : child.value,
              });
            }
          })}
      </div>
    );
  };
  // GENERATE SNIPPET
  const generateSnippet = () => {
    try {
      return (
        <div className="codeSnippetImageContainer">
          <img
            className="codeSnippetImage"
            style={{ width: snippet.width, left: snippet.offset && "-0.6rem" }}
            alt="Code Snippet"
            src={require(`../assets/code/${path.directory}/${path.page}.png`)}
            onLoad={(data) => {
              const { naturalHeight, naturalWidth } = data.target;
              const width = isMobile ? naturalWidth / 4 : naturalWidth / 2;
              const offset = naturalHeight > 4872 ? true : false;
              setSnippet({ width, offset });
            }}
          />
        </div>
      );
    } catch (error) {
      return null;
    }
  };
  // RENDER
  return (
    <div className="codeContainer">
      {/* HEADER */}
      <div className="codeHeader">
        <div className="codeHeaderButtons">
          <button
            className="codeHeaderButton"
            onClick={() => setNav({ 0: [], 1: [], 2: [] })}
          >
            <VscCollapseAll className="codeHeaderIcon" />
            {isDesktop && "Collapse"}
          </button>
          <button
            className="codeHeaderButton"
            onClick={() => {
              setHeader({ tabs: [], selected: "", disabled: "" });
              setPath({ directory: "", parents: [], page: "" });
            }}
          >
            <VscCloseAll className="codeHeaderIcon" />
            {isDesktop && "Close Tabs"}
          </button>
        </div>
        {/* TABS */}
        <div className="codeTabs">
          {header.tabs.map((tab, index) => {
            const { title, icon, directory, parents } = tab;
            const isJS = title.split(".")[1] === "js";
            const id = `${directory}-${title}`;
            const selected =
              path.directory === directory && path.page === title;
            return (
              <div
                key={index}
                className={selected ? "codeTabSelected" : "codeTab"}
                id={id}
                draggable={true}
                onDragOver={(event) => event.preventDefault()}
                onDragStart={() => {
                  setHeader({ ...header, selected: tab });
                  setPath({ directory, parents, page: title });
                }}
                onDrop={(event) => {
                  const filterdTabs = header.tabs.filter(
                    (t) => JSON.stringify(t) !== JSON.stringify(header.selected)
                  );
                  const dropTab = header.tabs.find(
                    (t) =>
                      `${t.directory}-${t.title}` === event.currentTarget.id
                  );
                  const indexOfDrop = header.tabs.indexOf(dropTab);
                  filterdTabs.splice(indexOfDrop, 0, header.selected);
                  setHeader({ tabs: filterdTabs, selected: "" });
                }}
                onClick={() => {
                  if (header.disabled !== id) {
                    setPath({ directory, parents, page: title });
                    isMobile && setFullscreen(true);
                  }
                }}
              >
                <img
                  className="codeTabIcon"
                  style={{
                    height: isJS && "1rem",
                  }}
                  alt={`${title} Icon`}
                  src={icon}
                />
                <p className="codeTabText">{title.replace("-", "")}</p>
                <button
                  className="codeTabCloseButton"
                  onClick={() => {
                    if (selected) {
                      if (header.tabs.length === 1) {
                        setPath({ directory: "", parents: [], page: "" });
                      } else if (index + 1 >= header.tabs.length) {
                        const previousTab = header.tabs[index - 1];
                        setPath({
                          directory: previousTab.directory,
                          parents: previousTab.parents,
                          page: previousTab.title,
                        });
                      } else {
                        const nextTab = header.tabs[index + 1];
                        setPath({
                          directory: nextTab.directory,
                          parents: nextTab.parents,
                          page: nextTab.title,
                        });
                      }
                    }
                    const updatedTabs = header.tabs.filter(
                      (t) => JSON.stringify(t) !== JSON.stringify(tab)
                    );
                    setHeader({ ...header, tabs: updatedTabs });
                  }}
                  onMouseOver={() => setHeader({ ...header, disabled: id })}
                  onMouseLeave={() => setHeader({ ...header, disabled: "" })}
                >
                  <VscClose className="codeTabIcon" />
                </button>
              </div>
            );
          })}
        </div>
        {/* PATH */}
        <div
          className="codePathContainer"
          style={{
            height: !pageExists && "0rem",
            display: isMobile && "none",
          }}
        >
          {path.parents.map((parent, index) => {
            const { title, icon } = parent;
            const isJS = title.split(".")[1] === "js";
            const showArrow = index + 1 < path.parents.length;
            return (
              <div key={index} className="codePath">
                <img
                  className="codePathIcon"
                  style={{
                    height: isJS && "0.8rem",
                  }}
                  alt={`${title} Icon`}
                  src={icon}
                />
                <p className="codePathText">
                  {title.replace("-", "")}
                  {showArrow && <FaAngleRight className="codePathIcon" />}
                </p>
              </div>
            );
          })}
        </div>
      </div>
      {/* NAV */}
      <div className="codeNavContainer">
        {code.map((project) => {
          const { title, components, helpers, pages, styles, type } = project;
          const isServer = type === "server";
          return generateDirectory({
            title,
            level: 0,
            icons: {
              open: require(`../assets/code/_icons/${
                isServer ? "server" : "folder"
              }-open.png`),
              closed: require(`../assets/code/_icons/${
                isServer ? "server" : "folder"
              }-closed.png`),
            },
            directory: title,
            parents: [
              {
                title,
                icon: require(`../assets/code/_icons/${
                  isServer ? "server" : "folder"
                }-open.png`),
              },
            ],
            children: isServer
              ? [...pages]
              : [
                  ...(components.length > 0
                    ? [{ title: "components", value: components }]
                    : []),
                  ...(helpers.length > 0
                    ? [{ title: "helpers", value: helpers }]
                    : []),
                  ...(pages.length > 0
                    ? [{ title: "pages", value: pages }]
                    : []),
                  ...(styles.length > 0
                    ? [{ title: "styles", value: styles }]
                    : []),
                  ...(type === "web"
                    ? ["App.js"]
                    : type === "app"
                    ? ["App.js", "Web.js"]
                    : []),
                ],
          });
        })}
      </div>
      {/* SNIPPET */}
      <div
        className={
          fullscreen ? "codeSnippetFullscreenContainer" : "codeSnippetContainer"
        }
        style={{
          background: pageExists ? css.codeBlack : css.codeGray,
        }}
      >
        {pageExists && (
          <button
            className="codeSnippetFullscreenButton"
            style={{
              top: isMobile ? "1rem" : fullscreen && "7rem",
            }}
            onClick={() => {
              setFullscreen(!fullscreen);
              isMobile && setPath({ directory: "", parents: [], page: "" });
            }}
          >
            {fullscreen ? <AiOutlineFullscreenExit /> : <AiOutlineFullscreen />}
          </button>
        )}
        {generateSnippet()}
      </div>
    </div>
  );
};
// EXPORT
export default Code;
