// IMPORTS
import React, { useEffect, useRef, useState } from "react";
import anime from "animejs";
import { useReactToPrint } from "react-to-print";
import { FaGlobe, FaPhoneAlt, FaEnvelope } from "react-icons/fa";
import { experience, skills } from "../helpers/resume";
import css from "../styles/modules/_exports.scss";
// COMPONENT
const Resume = ({ props }) => {
  // STATE
  const {
    state: {
      nav: { page },
      settings: { browser, touchDevice, isDesktop },
    },
  } = props;
  const isFirefox = browser === "firefox";
  const [skillset, setSkillset] = useState(skills({ darkMode: false }));
  const [printing, setPrinting] = useState(false);
  const [imageHeight, setImageHeight] = useState(0);
  const resume = useRef(null);
  // HANDLE PRINT
  const handlePrint = useReactToPrint({
    content: () => resume.current,
    onAfterPrint: () => setPrinting(false),
  });
  // LIFE CYCLE
  useEffect(() => {
    anime({
      targets: ".resumeContainer",
      left: page === "Resume" ? "0%" : "-100%",
      duration: 400,
      easing: "linear",
    });
    setImageHeight(resume.current.scrollHeight);
  }, [page]);
  // RENDER
  return (
    <div className="resumeContainer">
      {/* PRINT BUTTON */}
      {!touchDevice && (
        <button
          className="resumePrintButton"
          onClick={() => {
            setPrinting(true);
            setSkillset(skills({ darkMode: isFirefox }));
            setTimeout(() => {
              handlePrint();
            }, 1000);
          }}
        >
          {printing ? <span className="resumePrintButtonThrobber" /> : "Print"}
        </button>
      )}
      {/* PRINT POPUP */}
      {printing && <div className="resumePrintPopup">Preparing to Print</div>}
      {/* RESUME */}
      <div ref={resume} className={printing ? "resumePrinting" : "resume"}>
        {/* IMAGE */}
        {touchDevice && (
          <img
            className="resumeImage"
            style={{
              height: imageHeight,
            }}
            alt="Devin Juett Resume"
            src={require("../assets/docs/Devin-Juett-Resume.png")}
          />
        )}
        {/* HEADER */}
        <div
          className="resumeHeaderContainer"
          style={{
            height: printing && "10%",
          }}
        >
          {printing && (
            <p
              className="resumeTitle"
              style={{
                WebkitTextFillColor: isFirefox && css.black,
              }}
            >
              Devin Juett
            </p>
          )}
          <div className="resumeHeaderInfo">
            {[
              {
                text: "devinjuett.com",
                icon: <FaGlobe className="resumeHeaderIcon" />,
              },
              {
                text: "403-495-9001",
                icon: <FaPhoneAlt className="resumeHeaderIcon" />,
              },
              {
                text: "juettdm@gmail.com",
                icon: <FaEnvelope className="resumeHeaderIcon" />,
              },
            ].map(({ text, icon }, index) => {
              return (
                <p
                  key={index}
                  className="resumeHeaderText"
                  style={{
                    fontSize: printing && "2rem",
                    color: isFirefox && printing && css.black,
                  }}
                >
                  {icon}
                  {!isDesktop && <br />}
                  {text}
                </p>
              );
            })}
          </div>
        </div>
        {/* SKILLSET */}
        <div className="resumeSkillsetsContainer">
          <p
            className="resumeTitle"
            style={{
              fontSize: printing && css.mediumFont,
              WebkitTextFillColor: isFirefox && css.black,
            }}
          >
            Skillset
          </p>
          {Object.keys(skillset).map((type, index1) => {
            return (
              <div
                key={index1}
                className="resumeSkillsetContainer"
                style={{
                  borderBottom:
                    index1 === 3
                      ? "none"
                      : isFirefox && printing && `0.1rem solid ${css.black}`,
                }}
              >
                <p className="resumeSkillsetTitle">{type}</p>
                <div className="resumeSkillset">
                  {skillset[type].map(({ title, icon }, index2) => {
                    return (
                      <div key={index2} className="resumeSkill">
                        {icon}
                        <p
                          className="resumeSkillText"
                          style={{
                            fontSize: printing && css.smallestFont,
                          }}
                        >
                          {title}
                        </p>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
        {/* EXPERIENCE */}
        <div className="resumeExperienceContainer">
          <p
            className="resumeTitle"
            style={{
              fontSize: printing && css.mediumFont,
              WebkitTextFillColor: isFirefox && css.black,
            }}
          >
            Experience
          </p>
          {experience.map(({ title, text }, index1) => {
            return (
              <div
                key={index1}
                className="resumeExperience"
                style={{
                  borderBottom:
                    index1 === 3
                      ? "none"
                      : isFirefox && printing && `0.1rem solid ${css.black}`,
                }}
              >
                <p className="resumeExperienceTitle">{title}</p>
                <div className="resumeExperienceContentContainer">
                  {text.map((string, index2) => {
                    return (
                      <p
                        key={index2}
                        className="resumeExperienceText"
                        style={{
                          fontSize: printing && css.smallFont,
                          lineHeight: printing && "3rem",
                        }}
                      >
                        {string}
                      </p>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
// EXPORT
export default Resume;
