// IMPORTS
import React from "react";
import * as Icon from "react-icons/si";
import { FaDatabase } from "react-icons/fa";
import { GiPistolGun } from "react-icons/gi";
import { GrStripe } from "react-icons/gr";
// SKILLS
const skills = ({ darkMode }) => {
  return {
    "front end": [
      { title: "React", icon: <Icon.SiReact className="react" /> },
      { title: "Angular", icon: <Icon.SiAngular className="angular" /> },
      { title: "Vue", icon: <Icon.SiVuedotjs className="vue" /> },
      { title: "JQuery", icon: <Icon.SiJquery className="jquery" /> },
      { title: "HTML", icon: <Icon.SiHtml5 className="html" /> },
      { title: "CSS", icon: <Icon.SiCss3 className="css" /> },
      { title: "Sass", icon: <Icon.SiSass className="sass" /> },
      {
        title: "Three.js",
        icon: (
          <Icon.SiThreedotjs className={darkMode ? "threeDark" : "three"} />
        ),
      },
      {
        title: "Next.js",
        icon: <Icon.SiNextdotjs className={darkMode ? "nextDark" : "next"} />,
      },
      {
        title: "Typescript",
        icon: <Icon.SiTypescript className="typescript" />,
      },
      { title: "Redux", icon: <Icon.SiRedux className="redux" /> },
    ],
    middleware: [
      { title: "Node", icon: <Icon.SiNodedotjs className="nodejs" /> },
      { title: "GraphQL", icon: <Icon.SiGraphql className="graphql" /> },
      {
        title: "Coinbase",
        icon: (
          <img
            className="coinbase"
            alt="Coinbase"
            src={require("../assets/icons/coinbase.png")}
          />
        ),
      },
      { title: "Stripe", icon: <GrStripe className="stripe" /> },
      {
        title: "PayPal",
        icon: (
          <img
            className="paypal"
            alt="PayPal"
            src={require("../assets/icons/paypal.png")}
          />
        ),
      },
    ],
    "back end": [
      { title: "SQL", icon: <FaDatabase className="sql" /> },
      { title: "MongoDB", icon: <Icon.SiMongodb className="mongoDB" /> },
      { title: "Firebase", icon: <Icon.SiFirebase className="firebase" /> },
      { title: "Meteor", icon: <Icon.SiMeteor className="meteor" /> },
      { title: "Wordpress", icon: <Icon.SiWordpress className="wordpress" /> },
      { title: "Redis", icon: <Icon.SiRedis className="redis" /> },
      {
        title: "GunDB",
        icon: <GiPistolGun className={darkMode ? "gunDark" : "gun"} />,
      },
    ],
    other: [
      { title: "React Native", icon: <Icon.SiReact className="react" /> },
      {
        title: "Unity",
        icon: <Icon.SiUnity className={darkMode ? "unityDark" : "unity"} />,
      },
      { title: "C#", icon: <Icon.SiCsharp className="cSharp" /> },
      {
        title: "Python",
        icon: (
          <img
            className="python"
            alt="Python"
            src={require("../assets/icons/python.png")}
          />
        ),
      },
      {
        title: "Java",
        icon: (
          <img
            className="java"
            alt="Java"
            src={require("../assets/icons/java.png")}
          />
        ),
      },
      {
        title: "Linux",
        icon: (
          <img
            className="linux"
            alt="Linux"
            src={require("../assets/icons/linux.png")}
          />
        ),
      },
      {
        title: "Github",
        icon: <Icon.SiGithub className={darkMode ? "githubDark" : "github"} />,
      },
      {
        title: "Photoshop",
        icon: <Icon.SiAdobephotoshop className="photoshop" />,
      },
    ],
  };
};
// EXPERIENCE
const experience = [
  {
    title: "Coding",
    text: [
      "• Coding for over 4 years. Specializing in Web, App and Game development.",
      "• Building several full stack applications while using various languages, frameworks and libraries.",
    ],
  },
  {
    title: "Crypto Mining",
    text: [
      "• I began crypto mining in October 2020.",
      "• My mining farm is contained within a hydroponic tent for cooling and consists of 28 GPU's mining Ethereum at 1.2 Gh/s.",
    ],
  },
  {
    title: "Convergint",
    text: [
      "• Security Technician from November 2019 to November 2021.",
      "• Installed and maintained security hardware at the Calgary Airport while using SQL to filter video data and set user access.",
    ],
  },
  {
    title: "Other",
    text: [
      "• Taught Enlgish in Beijing, China from August 2018 to August 2019.",
      "• Disability support worker from September 2017 to July 2018.",
    ],
  },
];
// EXPORTS
export { skills, experience };
