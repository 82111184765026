// IMPORTS
import React, { useState, useEffect, useRef } from "react";
import anime from "animejs";
// COMPONENT
const Website = ({ props }) => {
  // STATE
  const { state, setState } = props;
  const { page, project } = state.nav;
  const { isMobile } = state.settings;
  const [loaded, setLoaded] = useState(false);
  const website = useRef(null);
  let showing = useRef(false);
  // LIFE CYCLE
  useEffect(() => {
    anime({
      targets: ".websiteContainer",
      left: showing.current
        ? page === "Resume"
          ? "100%"
          : (page === "Code" || page === "Projects") && "-100%"
        : page === "Website" && "0%",
      duration: 400,
      easing: "linear",
    });
    showing.current &&
      page !== "Website" &&
      setTimeout(() => {
        anime({
          targets: ".websiteContainer",
          left: "-100%",
          duration: 0,
        });
        setState((currentState) => {
          return {
            ...currentState,
            nav: { ...currentState.nav, project: null },
          };
        });
        setLoaded(false);
      }, 500);
    page === "Website" ? (showing.current = true) : (showing.current = false);
  }, [page, setState]);
  // RENDER
  return project ? (
    <div className="websiteContainer">
      {isMobile &&
        project.show.helperText &&
        window.matchMedia("(orientation: portrait)").matches && (
          <p className="websiteHelperText">
            Rotate your device for a better playing experience.
          </p>
        )}
      <iframe
        ref={website}
        title={project.title}
        className="websiteIframe"
        src={project.url}
        onLoad={() => {
          setTimeout(() => {
            setLoaded(true);
            website.current.focus();
          }, 1000);
        }}
      />
      {!loaded && <div className="websiteLoading">Loading</div>}
    </div>
  ) : null;
};
// EXPORT
export default Website;
