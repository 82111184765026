// IMPORTS
import React, { useRef, useEffect } from "react";
import anime from "animejs";
import { FaMobileAlt, FaTabletAlt, FaDesktop } from "react-icons/fa";
import { projects } from "../helpers/projects";
// COMPONENT
const Projects = ({ props }) => {
  // STATE
  const { state, setState } = props;
  const {
    nav: { page },
    settings: { isMobile, isTablet, isDesktop },
  } = state;
  let showing = useRef(false);
  // LIFE CYCLE
  useEffect(() => {
    anime({
      targets: ".projectsContainer",
      right: showing.current
        ? page === "Resume" || page === "Website" || page === null
          ? "-100%"
          : page === "Code" && "100%"
        : page === "Projects" && "0%",
      duration: 400,
      easing: "linear",
    });
    showing.current &&
      page === "Code" &&
      setTimeout(() => {
        anime({
          targets: ".projectsContainer",
          right: "-100%",
          duration: 0,
        });
      }, 500);
    page === "Projects" ? (showing.current = true) : (showing.current = false);
  }, [page]);
  // RENDER
  return (
    <div className="projectsContainer">
      {projects.map((project, index1) => {
        const { title, image, stack, show } = project;
        return (
          <div
            key={index1}
            className="project"
            onClick={() => {
              if (
                (isMobile && show.mobile) ||
                (isTablet && show.tablet) ||
                isDesktop
              ) {
                setState({ ...state, nav: { page: "Website", project } });
              }
            }}
          >
            {/* IMAGE */}
            <img
              className="projectImage"
              alt={`${title} preview`}
              src={image}
            />
            {/* DETAILS */}
            <div className="projectDetails">
              <p className="projectTitle">{title}</p>
              {/* PLATFORM */}
              <div className="projectPlatformContainer">
                {show.mobile && (
                  <p className="projectPlatform">
                    <FaMobileAlt className="projectPlatformIcon"/> Mobile
                  </p>
                )}
                {show.tablet && (
                  <p className="projectPlatform">
                    <FaTabletAlt className="projectPlatformIcon"/> Tablet
                  </p>
                )}
                <p className="projectPlatform">
                  <FaDesktop className="projectPlatformIcon"/> Desktop
                </p>
              </div>
              {/* STACK */}
              <div className="projectStackContainer">
                {stack.map(({ title, icon }, index) => {
                  return (
                    <p key={index} className="projectStackItem">
                      {icon} {title}
                    </p>
                  );
                })}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
// EXPORT
export default Projects;
